import { IConfigurationState } from "./configuration.interface";
import { ActionTypes, ConfigurationActionType } from "./configuration.types";
import {
  ActionTypes as UserDataActionTypes,
  UserActionTypes,
} from "redux/user/user.types";
import {
  ActionTypes as GameActionType,
  GameActionTypes,
} from "../game/game.types";
import { UserState } from "redux/user/user.interface";
import { Duration, IStoreGame } from "redux/game/game.interface";
import {
  SessionActionsTypes,
  ActionTypes as SessionActionsType,
} from "redux/session/session.types";

export const defaultUserData: UserState = {
  selectedCompany: null,
  nps: -1,
  user: {
    email: "",
    firstName: "",
    phoneNumber: "",
  },
};

const defaultGame: IStoreGame = {
  canPlayAgain: null,
  reward: {
    couponCode: "",
    name: "1 cadeau",
    picture: "https://game.myli.io//static/images/spin-game/gift.png",
    shareUrl: "",
    pc: {
      duration: 0,
      period: Duration.DAILY,
      minimumAmount: 0,
    },
  },
  won: null,
};

const initialState: IConfigurationState = {
  isReady: false,
  winners: [],
  winnersCount: 0,
  session: null,
  configuration: null,
  userData: defaultUserData,
  game: defaultGame,
  reward: null,
  isHappy: false,
  isComingFromHome: false,
};

type actions =
  | ConfigurationActionType
  | UserActionTypes
  | GameActionTypes
  | SessionActionsTypes;

export const configurationReducer = (
  state: IConfigurationState = initialState,
  action: actions
): IConfigurationState => {
  switch (action.type) {
    case ActionTypes.SET_FORM_SUBMIT_STATUS:
      return {
        ...state,
        fromFilled: state.fromFilled || action.payload.cs,
      };
    case ActionTypes.SET_CONFIGURATION:
      return {
        ...state,
        isReady: false,
      };
    case ActionTypes.SET_CONFIGURATION_SUCCESS:
      return {
        ...state,
        configuration: action.payload,
        isReady: true,
      };
    case ActionTypes.SET_CONFIGURATION_ERROR:
      return {
        ...state,
        configuration: state.configuration || null,
        isReady: true,
      };
    case ActionTypes.SET_WINNERS_SUCCESS:
      return {
        ...state,
        winners: action.payload,
      };
    case ActionTypes.SET_WINNERS_COUNT: {
      return {
        ...state,
        winnersCount: action.payload,
      };
    }
    case ActionTypes.SET_IS_COMING_FROM_HOME:
      return {
        ...state,
        isComingFromHome: true,
      };
    case ActionTypes.SET_FORM_FILLED:
      return {
        ...state,
        fromFilled: true,
      };
    case UserDataActionTypes.SET_SELECTED_COMPANY: {
      return {
        ...state,
        userData: {
          ...state.userData,
          selectedCompany: action.payload,
        },
      };
    }
    case UserDataActionTypes.SET_NPS_SCORE: {
      return {
        ...state,
        userData: {
          ...state.userData,
          nps: action.payload,
        },
      };
    }
    case GameActionType.SET_GAME_CAN_PLAY_AGAIN: {
      return {
        ...state,
        game: {
          ...state.game,
          canPlayAgain: {
            ...state.game.canPlayAgain,
            ...action.payload,
          },
        },
      };
    }
    case GameActionType.SET_GAME_PLAY: {
      return {
        ...state,
        game: {
          ...state.game,
          won: action.payload.won,
          reward: action.payload.reward,
        },
      };
    }
    case GameActionType.RESET_GAME: {
      return {
        ...state,
        game: {
          ...state.game,
          reward: null,
          won: null,
        },
      };
    }
    case UserDataActionTypes.SET_USER_DATA: {
      return {
        ...state,
        userData: {
          ...state.userData,
          user: action.payload,
        },
      };
    }
    case GameActionType.SET_GAME_REWARD_INFO: {
      return {
        ...state,
        reward: action.payload,
      };
    }
    case GameActionType.SET_GAME_REWARD_INFO_ERROR: {
      return {
        ...state,
        reward: action.payload,
      };
    }
    case GameActionType.SET_GAME_REWARD_VALIDATED: {
      return {
        ...state,
        reward: state.reward && {
          ...state.reward,
          ra: state.reward.ra ? state.reward.ra : new Date().toISOString(),
        },
      };
    }
    case UserDataActionTypes.SET_SATISFACTION_INQUIRY: {
      return {
        ...state,
        isHappy: action.payload,
      };
    }
    case SessionActionsType.SET_SESSION: {
      return {
        ...state,
        session: action.payload,
      };
    }

    default:
      return state;
  }
};
