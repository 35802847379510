import Layout from "components/Layout";
import CouponDiv from "./CouponDiv";
import Barcode from "react-barcode";
import Button from "components/Inputs/Button";
import { useModal } from "utils/hookedComponents/Modal/core/modal.hook";
import Modal from "utils/hookedComponents/Modal";
import ModalCollectFeedback from "components/Modals/ModalCollectFeedback";
import { useSelector } from "react-redux";
import { IConfigurationState } from "redux/configuration/configuration.interface";
import { useEffect, useState } from "react";
import { formatRemainingTime } from "pages/Collect/core/collect.helpers";
import { getPrizeExpirationDate } from "utils";

const claimLimitTime = 300;

export default function CollectBarCode() {
  const [isVisible, showModal, , reference] = useModal();
  const { reward } = useSelector((state: IConfigurationState) => state);

  const [elapsedTime, setElapsedTime] = useState<number>(0);

  useEffect(() => {
    const interval = setInterval(() => {
      const remainingTime = claimLimitTime - elapsedTime;
      if (remainingTime <= 0) {
        setElapsedTime(0);
        return;
      }
      setElapsedTime(elapsedTime + 1);
    }, 1000);

    return () => clearInterval(interval);
  }, [setElapsedTime, elapsedTime]);

  return (
    <Layout className="game bg-green-50">
      <CouponDiv hideTopCircle circleColor="bg-green-50">
        <div className="flex py-7 w-full items-center justify-center flex-col gap-5">
          Vous avez gagné un
          <div className="text-green-600 font-bold uppercase text-2xl">
            {reward.n}
          </div>
        </div>
      </CouponDiv>
      <CouponDiv circleColor="bg-green-50">
        <div className="flex border-t-4 border-green-600 border-dashed w-full items-center justify-center flex-col gap-5">
          <div className="w-full border-t border-green-600 bg-green-600 text-white py-2">
            Valable pendant: {formatRemainingTime(claimLimitTime - elapsedTime)}{" "}
            min
          </div>
        </div>

        <div className="flex items-center justify-center">
          {/*@ts-ignore */}
          <Barcode width={2} value={reward.code} />
        </div>
      </CouponDiv>
      <CouponDiv circleColor="bg-green-50 ">
        <div className="border-t-4 border-dashed border-green-50 py-6 flex flex-col gap-3">
          <p className="text-sm">Code promo à activer :</p>
          <p className="text-green-600">{reward.code}</p>
        </div>
      </CouponDiv>
      <CouponDiv hideBottomCircle circleColor="bg-green-50">
        <div className="flex flex-col border-t-4 border-dashed border-green-50 gap-1 items-center justify-center py-3">
          <p className="font-semibold">Notes et Conditions </p>
          <p className="text-sm">
            Lot activé le :{" "}
            <span className="font-medium">
              {formatDateWithFrenchTime(reward.wa)}
            </span>
            <br />
            Valable pour tout achat supérieur à {reward.pc?.minimumAmount}€
            <br /> dans votre {reward.cn}.
            <br />
            Offre valable juqu&apos;au{" "}
            {getPrizeExpirationDate(
              reward.pc.duration,
              reward.pc.period,
              reward.wa
            )}
          </p>
        </div>
      </CouponDiv>
      <Button
        onClick={showModal}
        text="J’ai récupéré mon lot"
        appearance="none"
        className="bg-green-600 w-full shadow-md mt-6"
      />
      <Modal
        content={<ModalCollectFeedback />}
        isVisible={isVisible}
        reference={reference}
      />
    </Layout>
  );
}

function formatDateWithFrenchTime(dateString: string): string {
  try {
    const date = new Date(dateString);

    // Check if the date is valid
    if (isNaN(date.getTime())) {
      throw new Error("Invalid date string");
    }

    // Pad single digits with leading zero
    const pad = (num: number): string => num.toString().padStart(2, "0");

    const day = pad(date.getDate());
    const month = pad(date.getMonth() + 1); // Months are 0-based
    const year = date.getFullYear();
    const hours = pad(date.getHours());
    const minutes = pad(date.getMinutes());

    return `${day}/${month}/${year} à ${hours}:${minutes}`;
  } catch (error) {
    console.error("Error formatting date:", error);
    return "";
  }
}
