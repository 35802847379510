import Button from "components/Inputs/Button";
import { FC, useContext } from "react";
import { Trans, useTranslation } from "react-i18next";
import Modal from "utils/hookedComponents/Modal";
import { useModal } from "utils/hookedComponents/Modal/core/modal.hook";
import ModalCollectDisclaimer from "../ModalCollectDisclaimer";
import { handleCTAClick } from "./core/modalCollectInstructions.helpers";
import { IProps } from "./core/modalCollectInstructions.types";
import { useDispatch, useSelector } from "react-redux";
import { IConfigurationState } from "redux/configuration/configuration.interface";
import { useNavigate, useParams } from "react-router";
import { CollectContext } from "pages/Collect";
import { setRewardValidation } from "redux/game/game.actions";

const ModalCollectInstructions: FC<IProps> = ({ hideModal }) => {
  const [
    isModalCollectDisclaimerVisible,
    showModalCollectDisclaimer,
    hideModalCollectDisclaimer,
    referenceModalCollectDisclaimer,
  ] = useModal();
  const { t } = useTranslation("modals", { keyPrefix: "collect.instructions" });
  const { reward } = useSelector((state: IConfigurationState) => state);
  const collectionPlace = reward?.cp;
  const toWhom = reward?.tw;
  const navigate = useNavigate();
  const { entityId } = useParams();

  const collectContext = useContext(CollectContext);
  const { rewardId, companyId } = { ...collectContext };

  const dispatch = useDispatch();

  return (
    <div className="modal__content">
      <div className="modal__img">
        <img src="/static/images/modals/check-mark.png" alt="check emoji" />
      </div>
      <h2 className="modal__title">{t("title")}</h2>
      <p className="mb-2">
        <Trans
          ns="modals"
          i18nKey="collect.instructions.subtitle"
          components={[<span className="c-primary fw-700"></span>]}
        />
        <span className="c-primary fw-700">
          {t("collectionPlace", { collectionPlace })}
        </span>
      </p>
      <p>
        <span>{t("subtitle2")}</span>
        <span className="c-primary fw-700">{t("toWhom", { toWhom })}</span>
      </p>
      <Button
        onClick={
          reward?.rtid === 4
            ? () => {
                // @ts-ignore

                dispatch(setRewardValidation(companyId, rewardId));

                setTimeout(() => {
                  navigate(`/${entityId}/collect-code`);
                }, 1000);
              }
            : handleCTAClick(showModalCollectDisclaimer)
        }
        text={t("CTA")}
        className="modal__btn mb-3"
      />

      <Modal
        position="center"
        reference={referenceModalCollectDisclaimer}
        isVisible={isModalCollectDisclaimerVisible}
        content={
          <ModalCollectDisclaimer
            hideModal={hideModalCollectDisclaimer}
            hideModalCollectInstructions={hideModal}
          />
        }
      />
    </div>
  );
};

export default ModalCollectInstructions;
