import { useCallback } from "react";
import { LeadField } from "redux/configuration/configuration.interface";

type Props = {
  leadFiled: LeadField;
  setFieldValue: (fieldId: string, value: string) => void;
};

export default function InfoField(props: Props) {
  const { leadFiled, setFieldValue } = props;

  const Header = useCallback(() => {
    return (
      <p className="block text-sm font-medium text-left mb-1 ">
        {leadFiled.label}

        {leadFiled.required && <span className="text-red-500">*</span>}
      </p>
    );
  }, [leadFiled.label, leadFiled.required]);

  if (leadFiled.type === "select") {
    return (
      <div>
        <Header />
        <select
          required={leadFiled.required}
          onChange={(e) => setFieldValue(leadFiled.id, e.target.value)}
          className="w-full p-2 border border-gray-300 rounded-md bg-white"
        >
          {leadFiled.options?.map((option) => (
            <option key={option} value={option}>
              {option}
            </option>
          ))}
        </select>
      </div>
    );
  }

  return (
    <div>
      <Header />
      <input
        type={leadFiled.type}
        maxLength={leadFiled.maxLength}
        minLength={leadFiled.minLength}
        placeholder={leadFiled.label}
        required={leadFiled.required}
        onChange={(e) => setFieldValue(leadFiled.id, e.target.value)}
        className="w-full p-2 border border-gray-300 rounded-md bg-white"
      />
    </div>
  );
}
