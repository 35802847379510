import Button from "components/Inputs/Button";
import Layout from "components/Layout";
import { useCallback, useMemo, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { IConfigurationState } from "redux/configuration/configuration.interface";
import { Icons } from "utils/constants/icons";
import InfoField from "./InfoField";
import ConsentField from "./ConsentField";
import {
  ActionTypes,
  AppThunkDispatch,
} from "redux/configuration/configuration.types";
import { useNavigate, useParams } from "react-router";
import { endpoints } from "config/endpoints";
import axios from "services/axios.service";

export default function InformationForm() {
  const [showForm, setShowForm] = useState(false);
  const dispatch: AppThunkDispatch = useDispatch();
  const navigate = useNavigate();
  const { entityId } = useParams();

  const { configuration } = useSelector((state: IConfigurationState) => {
    return state;
  });

  const leadSettings = useMemo(
    () => configuration?.s?.leadSettings,
    [configuration]
  );

  console.log("leadSettings", configuration, leadSettings);

  const [fieldData, setFieldData] = useState<Record<string, string>>({});
  const [consentData, setConsentData] = useState<Record<string, boolean>>({});
  const [error, setError] = useState<{
    id: string;
    field: string;
    code: string;
  }>(null);

  const handleFieldChange = useCallback((fieldId: string, value: string) => {
    setFieldData((prev) => ({ ...prev, [fieldId]: value }));
  }, []);

  const handleConsent = useCallback((id: string) => {
    setConsentData((prev) => ({ ...prev, [id]: !prev[id] }));
  }, []);

  const disabled = useMemo(() => {
    const requiredFields = leadSettings?.fields?.filter(
      (field) => field.required
    );
    const requiredConsents = leadSettings?.consents?.filter(
      (consent) => consent.required
    );
    if (!requiredFields && !requiredConsents) {
      return false;
    }

    return (
      requiredFields.some((field) => !fieldData[field.id]) &&
      requiredConsents.some((consent) => !consentData[consent.id])
    );
  }, [fieldData, consentData, leadSettings]);

  const handleSubmit = useCallback(async () => {
    try {
      let { data } = await axios.api.post(
        `${endpoints.api.formUpdate as any}`,
        {
          cid: entityId,
          fields: fieldData,
          consents: consentData,
        },
        {
          withCredentials: true,
        }
      );
      console.log("Submit", data);
      dispatch({
        type: ActionTypes.SET_FORM_FILLED,
      });
      navigate(`/${entityId}`);
    } catch (error) {
      console.log("Error", error?.response?.data);
      setError(error?.response?.data);
    }
  }, [fieldData, consentData, dispatch, entityId, navigate]);

  if (!showForm) {
    return (
      <Layout className="game bg-green-50">
        <div className="collect__img bg-green-500 mb-10 " />
        <p className="text-3xl font-extrabold uppercase mb-1">
          Grand jeu de <span className="text-green-600">Noel</span>
        </p>
        <p className="text-xl font-extrabold uppercase mb-1">
          La Hotte Magique du Père Noël
        </p>
        <p className="text-xl font-extrabold uppercase mb-5 text-green-600">
          921 - Feu Vert Marseille
        </p>

        <Button
          className="bg-green-600"
          appearance="primary"
          text={"Je participe"}
          onClick={() => setShowForm(true)}
          icon={Icons.chevronRight}
        />
      </Layout>
    );
  }

  return (
    <Layout className="  bg-green-50">
      <div className="flex flex-col items-center justify-center gap-8 bg-green-50 py-10">
        <section>
          <p className="text-3xl font-extrabold  mb-2">
            Plus que quelques instants avant de tenter votre chance!
          </p>
          <p>
            Pour participer au <span>tirage au sort</span>, merci de compléter
            les informations ci-dessous:
          </p>
        </section>

        <section className="flex flex-col gap-4 bg-[#CCE7D599] w-full p-6 rounded-md">
          {leadSettings?.fields?.map((leadField) => (
            <InfoField
              key={leadField.id}
              leadFiled={leadField}
              setFieldValue={handleFieldChange}
            />
          ))}
        </section>
        <section className="flex flex-col gap-2">
          {leadSettings?.consents?.map((leadConsent) => (
            <ConsentField
              checked={consentData[leadConsent.id] as boolean}
              key={leadConsent.id}
              leadConsent={leadConsent}
              handleConsent={handleConsent}
            />
          ))}
        </section>
        {error && (
          <section>
            <p className="text-red-500 text-sm">
              {error.field} {error.code}
            </p>
          </section>
        )}
        <Button
          disabled={disabled}
          appearance="none"
          text="Je joue"
          onClick={handleSubmit}
          className="bg-green-600 w-full shadow-md"
        />
        <button
          className="text-green-600 font-semibold"
          onClick={() => setShowForm(false)}
        >
          Annuler
        </button>
      </div>
    </Layout>
  );
}
