/*
Meaning of routes shortcuts
GET    /EiK3d23Jl2               --> github.com/myLocalInfluence/myLottery/v2/route.(*Campaign).GetCampaignInfo-fm (6 handlers)
POST   /Xt7bKpL9mQR              --> post user provided information 
GET    /jN3fWq8vZcY              --> Get information on if user can submit form data
POST   /ENDKri48ED0s             --> github.com/myLocalInfluence/myLottery/v2/route.(*Campaign).Play-fm (7 handlers)
GET    /MuSXUcbN7i               --> github.com/myLocalInfluence/myLottery/v2/route.(*User).CanUserPlayAgain-fm (7 handlers)
PUT    /D8kdl39dpSL              --> github.com/myLocalInfluence/myLottery/v2/route.(*User).EditUserOptions-fm (7 handlers)
GET    /HDn6XbjKmn               --> github.com/myLocalInfluence/myLottery/v2/route.(*User).RegisterAnonymous-fm (6 handlers)
GET    /DUI4kdn2L                --> github.com/myLocalInfluence/myLottery/v2/route.(*User).RegenerateJWTCurrentUser-fm (7 handlers)
POST   /MU49tyS84hdkS            --> github.com/myLocalInfluence/myLottery/v2/route.(*NPS).addNPSEntity-fm (7 handlers)
POST   /8e2E6dA55d               --> github.com/myLocalInfluence/myLottery/v2/route.(*Reward).ClaimReward-fm (7 handlers)
GET    /SI29dj2uSoe3             --> github.com/myLocalInfluence/myLottery/v2/route.(*Reward).GetRewardInformation-fm (6 handlers)
POST   /Z9kJki3djs2              --> github.com/myLocalInfluence/myLottery/v2/route.(*Satisfaction).addSatisfactionUser-fm (7 handlers)
GET    /ping                     --> github.com/myLocalInfluence/go-ginutils/v2/handler.Ping (2 handlers)
GET    /docs/                    --> main.main.func4 (2 handlers)
GET    /doc.json                 --> main.main.func5 (2 handlers)
GET    /                         --> main.main.func6 (2 handlers)
*/
export const endpoints = {
  api: {
    formSubmitCheck: "/jN3fWq8vZcY",
    configuration: "/EiK3d23Jl2",
    formUpdate: "/Xt7bKpL9mQR",
    winners: "/_mocks/winners/pitaya-angers.json",
    avatars: "/api",
    companies: "/_mocks/companies/pitaya-angers.json",
    nps: "/MU49tyS84hdkS",
    game: {
      canPlayAgin: "/MuSXUcbN7i",
      play: "/ENDKri48ED0s",
    },
    reward: {
      validate: "/8e2E6dA55d",
      info: "/SI29dj2uSoe3",
    },
    user: {
      setData: "/D8kdl39dpSL",
      setUserSatisfaction: "/Z9kJki3djs2",
      regenerateToken: "/DUI4kdn2L",
      newSession: "/HDn6XbjKmn",
    },
    events: {
      libPath: "js/lib_v0.0.8.js",
    },
  },
  mocks: {
    configuration: {
      "pitaya-angers": "/_mocks/configuration/pitaya-angers.json",
      pokawa: "/_mocks/configuration/pokawa.json",
    },
    winners: "/_mocks/winners/pitaya-angers.json",
    avatars: "/api",
    user: {
      setData: "/_mocks/user/data.json",
      setUserSatisfaction: "/_mocks/user/satisfaction.json",
      newSession: "/_mocks/session/pitaya-angers.json",
    },
    companies: "/_mocks/companies/pitaya-angers.json",
    nps: "/_mocks/nps/pitaya-angers.json",
    game: {
      canPlayAgain: "/_mocks/canPlayAgain/pitaya-angers.json",
      play: {
        win: {
          "8230dbe2-6aa1-4fab-8189-a5fc32ca0bdc":
            "/_mocks/play/win/pitaya-angers.json",
          pokawa: "/_mocks/play/win/pokawa.json",
        },
        lose: {
          "pitaya-angers": "/_mocks/play/lose/pitaya-angers.json",
          pokawa: "/_mocks/play/lose/pokawa.json",
        },
      },
    },
    reward: {
      validate: "/_mocks/rewards/validate.json",
      info: "/_mocks/rewards/info.json",
    },
  },
};
