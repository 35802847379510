type Props = {
  children: React.ReactNode;
  hideTopCircle?: boolean;
  hideBottomCircle?: boolean;
  circleColor?: string;
};

export default function CouponDiv(props: Props) {
  const { children, hideBottomCircle, hideTopCircle, circleColor } = props;
  return (
    <div className="relative rounded-md bg-white">
      {!hideTopCircle && (
        <>
          <Circle
            directionY="top"
            directionX="left"
            className={` ${circleColor}`}
          />
          <Circle
            directionY="top"
            directionX="right"
            className={` ${circleColor}`}
          />
        </>
      )}

      {!hideBottomCircle && (
        <>
          <Circle
            directionY="bottom"
            directionX="left"
            className={`${circleColor}`}
          />
          <Circle
            directionY="bottom"
            directionX="right"
            className={`${circleColor}`}
          />
        </>
      )}
      {children}
    </div>
  );
}

const Circle = ({
  className,
  directionX,
  directionY,
}: {
  className: string;
  directionX: "left" | "right";
  directionY?: "top" | "bottom";
}) => (
  <div
    className={`absolute w-10 h-10 rounded-full
    ${
      directionX === "left"
        ? "left-0 -translate-x-1/2"
        : "right-0 translate-x-1/2"
    }
    ${
      directionY === "top"
        ? "top-0 -translate-y-1/2"
        : "bottom-0 translate-y-1/2"
    }
    ${className} `}
  />
);
