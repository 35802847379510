import { LeadConsent } from "redux/configuration/configuration.interface";

type Props = {
  leadConsent: LeadConsent;
  handleConsent: (id: string) => void;
  checked: boolean;
};

export default function ConsentField(props: Props) {
  const { leadConsent, handleConsent, checked } = props;
  return (
    <div
      onClick={() => handleConsent(leadConsent.id)}
      className="flex items-start "
    >
      <div>
        <div className="p-1 mr-2 rounded-md border bg-white">
          <div
            className={`w-5 h-5 rounded-md ${checked ? "bg-green-600" : ""}`}
          />
        </div>
      </div>
      <p className="font-medium text-sm text-left">
        {leadConsent.label}
        {leadConsent.required && <span className="text-red-500">*</span>}
      </p>
    </div>
  );
}
