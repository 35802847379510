export interface IAPIGame {
  won: boolean;
  canPlayAgain: boolean;
  reward: IAPIGameReward | null;
}

interface IAPIGameReward {
  name: string;
  shareUrl: string;
  couponCode: string;
  picture: string;
  pc: PrizeConditions;
}

export enum Duration {
  DAILY = 1,
  WEEKLY = 2,
  MONTHLY = 3,
}

export interface PrizeConditions {
  duration: number;
  period: Duration;
  minimumAmount: number;
}

export interface IStoreGame {
  won: boolean | null;
  canPlayAgain: GamePlayAgain | null;
  reward: IAPIGameReward | null;
}

export interface GameRewardValidationResponse {
  success: boolean;
  error?: string;
  until: string;
}

export interface MarketActionObject {
  t: number;
  im: boolean;
  ofw: boolean;
}

export interface GameRewardInfo {
  dn?: string;
  bu: string;
  pColor?: string;
  pc: PrizeConditions;
  n?: string;
  pup?: "https://www.public-picture.fr/img.jpg";
  ra?: string;
  isFetched?: boolean;
  hasError?: boolean;
  wa?: string;
  ia?: number;
  lg: string[];
  cy: string;
  cp: string;
  tw: string;
  cc: boolean; // CanClaim (= NotExpired)
  cn?: string; // Company name (Etab)
  rtid: number; //reward_type_id 4= unique code
  code: string;
}

interface IAPIGameReward {
  name: string;
  shareUrl: string;
  couponCode: string;
  picture: string;
  ia?: number;
}

export interface GamePlayAgain {
  a?: number;
  c?: boolean;
  obj?: MarketActionObject;
}
